:root {
    --light-text-color: #fff;
    --dark-text-color: #ccc;
}

* {
    box-sizing: border-box;
    margin: 0;
}

a { color: #07b }

a:not(:hover) { text-decoration: none }

aside, section {
    display: grid;
    gap: 1rem;
}

b, mark {
    background: none;
    color: red;
    font-weight: inherit;
}

body {
    color: #444;
    display: flex;
    flex-direction: column;
    font: 16px system-ui, -apple-system, sans-serif;
    margin: auto;
    max-width: 64rem;
    min-height: 100vh;
    padding: 1rem 1rem 0;
}

body > header {
    align-items: center;
    border-bottom: .25rem solid currentColor;
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
}

body > header form {
    max-width: 15rem;
    width: 100%;
}

#logo {
    display: flex;
    margin-right: auto;
}

#logo svg {
    height: calc(2rem + 2px);
    width: calc(2rem + 2px);
}

footer {
    align-items: center;
    border-top: .125rem solid currentColor;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    padding: .5rem 0;
}

footer a { white-space: nowrap }

footer > :last-child {
    flex: 1;
    text-align: right;
}

h1 { font-size: 2.00rem }
h2 { font-size: 1.50rem }
h3 { font-size: 1.25rem }

/* Allow Very::Long::Raku::Module::Name to break anywhere */
h1, h2, h3 {
    overflow: hidden;
    overflow-wrap: break-word;
}

h2 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn, button, input[type=search], select {
    background: #fff;
    border-radius: .25rem;
    border: 1px solid #aaa;
    color: #444;
    font: inherit;
    line-height: 2;
    padding: 0 .5rem;
}

.btn, button {
    align-items: center;
    display: flex;
    gap: .375rem;
    text-decoration: none;
}

.btn-green {
    background: #28a745;
    border-color: #28a745;
    color: var(--light-text-color);
}

.btn, button, select { cursor: pointer }

.btn:hover {
    background: #aaa;
}

.btn-green:hover {
    background: #218838;
    border-color: #1e7e34;
}

input[type=search], select { width: 100% }

select {
    -webkit-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path d='M2 0L0 2h4zm0 5L0 3h4' fill='%23444'/></svg>") no-repeat right .5rem center/auto calc(100% - 1.5rem);
    padding-right: 1.5rem;
}

.btn:focus, button:focus, input[type=search]:focus, select:focus {
    border-color: #07b;
    box-shadow: 0 0 .25rem #07b;
    outline: 0;
}

main {
    align-content: start;
    align-items: start;
    display: grid;
    flex: 1;
    gap: 1rem 2rem;
    padding: 1rem 0;
}

nav { display: flex }

summary { cursor: pointer }

svg {
    fill: currentColor;
    height: 1rem;
    min-width: 1rem;
    width: 1rem;
}

table {
    border-collapse: collapse;
    font-variant: tabular-nums;
}

td, th { padding: .25rem .5rem }

thead th {
    background: #444;
    color: var(--light-text-color);
}

tr:nth-child(even) { background: #eee }

.badge {
    align-items: center;
    background: #444;
    border-radius: .5rem;
    color: var(--light-text-color);
    display: flex;
    font-size: .75rem;
    font-weight: bold;
    gap: .375rem;
    padding: .25rem .5rem;
}

.badge svg {
    height: .75rem;
    width: .75rem;
}

.eco {
    color: var(--light-text-color);
    font-size: .75rem;
    font-weight: bold;
    grid-row: span 2;
    padding: .25rem .125rem;
    writing-mode: vertical-rl;
}

/* grid-row: 1 / -1 doesn't work :-( */
.eco.with-tags { grid-row: span 3 }

/* RGB */
.eco.cpan { background: #BC4D43 }
.eco.p6c  { background: #43BC4D }
.eco.zef  { background: #4D43BC }

.tags {
    border: 1px solid #444;
    border-width: 1px 0 0 1px;
    display: inline-flex;
    flex-wrap: wrap;
}

.tags a {
    border: 1px solid #444;
    border-width: 0 1px 1px 0;
    flex: auto;
    font-size: .75rem;
    padding: .25rem .375rem;
    text-align: center;
    text-transform: uppercase;
}

.tags a:hover {
    background: #07b;
    color: var(--light-text-color);
    text-decoration: none;
}

.tags a:not([href]) {
    background: #444;
    color: var(--light-text-color);
}

#dists {
    display: grid;
    gap: 1rem;
    padding: 0;
}

#dists header {
    align-items: center;
    display: flex;
    gap: .5rem;
    overflow: hidden;
    white-space: nowrap;
}

#dists li:first-child {
    border-top: 1px solid currentColor;
    padding-top: 1rem;
}

#dists li {
    border-bottom: 1px solid currentColor;
    display: grid;
    gap: .5rem;
    grid-template-columns: auto 1fr;
    padding-bottom: 1rem;
}

#info {
    align-items: start;
    display: grid;
    gap: 0 .75rem;
    grid-template-columns: auto 1fr;
}

#info > * { min-height: 1.5rem }

#info img {
    border-radius: .5rem;
    grid-row: span 3;
    height: 5rem;
    width: 5rem;
}

#info li {
    align-items: center;
    display: flex;
    margin: .25rem 0 0 .5rem;
}

#info span {
    font-size: .75rem;
    font-weight: bold;
}

#info svg {
    fill: currentColor;
    height: 1rem;
    margin-right: .375rem;
    width: 1rem;
}

#info ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: -.25rem 0 0 -.5rem;
    padding: 0;
}

#pagination > :first-child { text-align: right }

#pagination > :not(:nth-child(2)) { flex: 1 }

#pagination > :nth-child(2) {
    font-variant: tabular-nums;
    margin: 0 .5rem;
}

#search { position: relative }

#search li {
    overflow: hidden;
    padding: .25rem .5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#search li:hover, #search li[aria-selected="true"] {
    background: #eee;
    cursor: pointer;
}

#search ul {
    background: #fff;
    border-radius: .25rem;
    border: 1px solid #aaa;
    list-style: none;
    max-height: 14rem;
    overflow-y: scroll;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

@media (max-width: 30rem) {
    body > header span { display: none }

    .btn { padding: .5rem }
}

@media (prefers-color-scheme: dark) {
    body { background-color: #212121 }

    body > header, #dists li, #dists li:first-child, footer {
        border-color: #555;
    }

    a { color: #59bbf3 }

    b { color: #e66868 }

    tr:nth-child(even), #search li:hover, #search li[aria-selected="true"] {
        background-color: #272727;
    }

    .btn, input[type=search], select, #search ul {
        background-color: #333;
        color: #ccc;
        border-color: #777;
    }

    select {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path d='M2 0L0 2h4zm0 5L0 3h4' fill='%23888'/></svg>");
    }

    body, thead th, .badge, .eco, .tags a:hover, .tags a:not([href]), select { color: var(--dark-text-color) }
}
